import { ParsedUrlQuery } from 'querystring';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { Alert, Text } from '@blockworks/ui/components';

import { AuthAlertShape, AuthErrorName, AuthErrorNameMessage } from '@/utils/auth/auth.errors';

const getAlert = (query: ParsedUrlQuery) => {
    const { error } = query;
    const errorName = `${error}` as AuthErrorName;
    return AuthErrorNameMessage[errorName];
};

export const AuthAlert = () => {
    const { query } = useRouter();
    const [alert, setAlert] = useState<AuthAlertShape | undefined>(() => getAlert(query));

    useEffect(() => {
        setAlert(getAlert(query));
    }, [query]);

    if (!alert) return null;

    return (
        <Alert intent={alert?.intent} fontSize="sm" p={2}>
            <Text size="sm">{alert?.message}</Text>
        </Alert>
    );
};
