import { TVStyleProps } from '../../style-system';
import { Button } from '../button';
import { FlexBox } from '../flex-box';
import { CheckCircleIcon, ExclamationPointCircleIcon, InfoCircleIcon, XCloseCircleIcon } from '../icon/icons';

import { alertStyles } from './alert.styles';

type AlertProps = React.PropsWithChildren<
    TVStyleProps<typeof alertStyles> & {
        onDismiss?: () => void;
        isDismissable?: boolean;
    }
>;

const IconMapping: { [key in NonNullable<AlertProps['intent']>]: typeof CheckCircleIcon } = {
    success: CheckCircleIcon,
    warning: ExclamationPointCircleIcon,
    danger: XCloseCircleIcon,
    info: InfoCircleIcon,
};

const Alert = ({
    children,
    variant = 'outline',
    intent = 'info',
    className,
    onDismiss,
    isDismissable,
    fontSize,
    size = 'md',
    px,
    py,
    p,
    mx,
    my,
    mt,
    mb,
    mr,
    ml,
    m,
}: AlertProps) => {
    const IconComponent = IconMapping[intent] || InfoCircleIcon;

    return (
        <div
            className={alertStyles({
                intent,
                variant,
                className,
                size,
                fontSize,
                px,
                py,
                p,
                mx,
                my,
                m,
                mt,
                mb,
                mr,
                ml,
            })}
        >
            <FlexBox gap="md" center>
                <FlexBox center display={{ initial: 'hidden', md: 'flex' }}>
                    <IconComponent className="w-[2em] h-[2em]" />
                </FlexBox>
                {children}
            </FlexBox>
            {isDismissable && (
                <Button variant="ghost" aria-label="Close" borderRadius="md" onClick={onDismiss}>
                    Close
                </Button>
            )}
        </div>
    );
};

export type { AlertProps };
export { Alert };
